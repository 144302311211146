/* Posicionamiento
Box Model
Tipografía
Visuales
Otros */
.errorSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-around;
    text-align: center;
}
h2 {
    color: var(--darkBlue); 
    font-weight: 500;
    font-size: 1.6rem;
}
.errorSection_warning {
    border: 2px solid red;
}
.errorSection_link {
    color: var(--darkBlue);
    width: 100%;
    margin: 12px;
    padding: 4px;
    font-size: 1.25rem;
    font-weight: 500;
    background-color: var(--transferGreen);
    border-radius: 25px;
}
