.button-credit_card {
    width: 100%;
    height: 50px;
    background-color: #b1b1b1;
    border-radius: 25px;
    cursor: pointer;
}
.button-credit_card:hover {
    transform: scale3d(1.02, 1.02, 1.02);
    -webkit-transform: scale3d(1.02, 1.02, 1.02);
    transition: all 0.3s;
}

.button-credit_card:enabled {
    background-color: #ffc500;
}

.components_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #efefef;
    padding: 30px;
    border-radius: 15px;
    height: 100%;
}
.credit_card_text {
    font-size: 1.4rem;
    font-weight: 500;
    color: #020c72;
}
.credit_card-image {
    width: 450px;
}