.btn-disabled-exchange {
  color: #ffffff;
  /* background-image: linear-gradient(to right, #18181850, #0f0f0f59); */
  background-color: rgba(153, 153, 153, 0.829);
  cursor: not-allowed;
}

.cuadro {
  border-radius: 10px;
}

.cuadro1 {
  background-color: rgba(153, 153, 153, 0.2);
  border-radius: 10px;
  color: white;
}

.cuadro-sell {
  background-color: rgba(153, 153, 153, 0.2);
  border-radius: 10px;
  color: white;
}

.ant-select-selection.ant-select-selection--single,
.ant-select-focused,
.ant-select-dropdown-menu,
.ant-select-dropdown-menu-item,
.ant-select-selection-selected-value {
  color: #020c72 !important;
  background-color: #69e1a0 !important;
  border: 1px solid #69e1a0 !important;
}

.ant-select-selection__placeholder, .ant-select-search__field__placeholder{
  color: #000000 !important;
}
.delete-button-exchange {
  background: white;
  border-radius: 3px;
  padding-right: 3px;
  padding-left: 3px;
  color: red;
  font-weight: bold;
  font-size: 0.8em;
}
