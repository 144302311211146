/* Posicionamiento
Box Model
Tipografía
Visuales
Otros */
.container_blue {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 650px;
    background-color:var(--darkBlue);
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 20px;
}
h3 {
    font-size: 1.3rem;
    color: var(--darkBlue);
}

main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.number_box, .amount-to-withdraw {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    margin: 4px;
}

.number_current {
    font-size: 2rem;
    font-weight: bolder;
}
.register_button {
    box-sizing: border-box;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--borderGray);
    max-width: 650px;
}

.amount-to-withdraw input {
    width: 100%;
    text-align: center;
    border: none;
    font-size: 2rem;
    font-weight: bolder;
    background-color: var(--darkGrey);
}

.amount-to-withdraw {
    background-color: var(--darkGrey);
}
.button-withdraw {
    width: 90%;
    margin: 12px;
    padding: 4px;
    font-size: 1.25rem;
    background-color: var(--borderGray);
    border-radius: 25px;
}
.button-withdraw:enabled {
    background-color: var(--buttonYellow);
}