.breadcrumbs{
    // display: block;
    position: fixed;
    width: calc(48px + 2em);
    top: 100px;
    right: 0px;
    z-index: 900;
    & li{
        // display: block;
        width: fit-content;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        // background-color: rgba(255,255,255,0.5);
        // background-image: linear-gradient(to bottom, rgba(15, 169, 201, 0.2), rgba(92, 200, 130, 0.2));
        // background-image: linear-gradient(to top, #A7F5FF, #EDF6F5);
        background-color: #fefefe;
        padding: 0.5em;
        margin-bottom: 0.5em;
        & img{
            display: block;
            width: 48px;
            height: 48px;
            transition: all .3s;
        }
        & img:hover{
            transform: scale3d(1.05,1.05,1.05);
            -webkit-transform: scale3d(1.05,1.05,1.05);
        }
    }
}
@media only screen and (max-width: 500px) {
    .breadcrumbs{
        display: flex;
        position: fixed;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: fit-content;
        // background-image: linear-gradient(to bottom, rgba(15, 169, 201, 0.75), #5CC882);
        // background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.75), rgba(255,255,255,1));
        // background-color: #0FA9C9;
        background-color: #fefefe;
        top: auto;
        bottom: 0px !important;
        box-shadow: 0px -3px 6px rgba(0,0,0,0.2);
        & li{
            border-radius: 0;
            padding: 0px;
            margin: 0.6em 0.2em;
            border-radius: 6px;
            padding: 0.2em;
            // background-color: #A7F5FF;
            // background-color: rgba(167, 245, 255, 0.5);
            // background-image: linear-gradient(to bottom, rgba(15, 169, 201, 0.2), rgba(92, 200, 130, 0.2));
            background-color: white;
            // box-shadow: inset 0px 0px 20px rgba(128,128,128,0.1);
        }
    }
}