@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

*,
*:before,
*:after {
  box-sizing: inherit;
}
* {
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", sans-serif !important;

  color: #3f3d56;
  font-size: 16px;
}
/* svg.apexcharts-svg g g text tspan{
  font-size: 10px !important;
  font-family: 'Open Sans', sans-serif !important;;
} */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

button {
  outline: none;
  border: none;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}
button.color1 span {
  color: #0d6e57;
}
.color1 {
  color: #0d6e57;
}
.bg1 {
  background-color: #0d6e57;
}
.color2 {
  color: #3f3d56;
}
.bg2 {
  background-color: #3f3d56;
}
.color3 {
  color: #0c6d56;
}
.bg3 {
  background-color: #0c6d56;
}
.color4 {
  color: #ffffff;
}
.bg4 {
  background-color: #ffffff;
}
.colo5 {
  background-color: #edf6f5;
}
.bg5 {
  background-color: #edf6f5;
}
.color6 {
  color: #dffed3;
}
.bg6 {
  background-color: #dffed3;
}
.color7 {
  color: #5cc882;
}
.color8 {
  color: #1c1d1c;
}
.bg7 {
  background-color: #5cc882;
}
.bg8 {
  background-color: #ffffff6b;
}
.color10 {
  color: #06419b;
}
.bg10 {
  background-color: #00669d;
}
.color11 {
  color: #07367c;
}
.bg11 {
  background-color: #07367c;
}
/* .color12{ color: #00E0A1; }
.bg12{ background-color: #00E0A1; } */
.color12 {
  color: #ffc500;
}
.bg12 {
  background-color: #ffc500;
}
.bg13 {
  background-color: #059b90;
}

.color-disabled {
  color: #989899;
}

/* Color inactivo */
.bgi {
  background-color: #b1b1b1;
}
.colori {
  color: #dbdbdb;
}

.gradient1 {
  background-image: linear-gradient(to right, #68ba81 30%, #bbdcbf);
}
.gradient2 {
  background-image: linear-gradient(to right, #59c0cb, #68ba8f);
}
.gradient3 {
  background-image: linear-gradient(to right, #0fa9c9, #5cc882);
}
.gradient3b {
  background-image: linear-gradient(to bottom, #0fa9c9, #5cc882);
}
.gradient4 {
  background-image: linear-gradient(to right, #a7f5ff, #edf6f5);
}
.gradient5 {
  background-image: linear-gradient(to right, #5cc882, #5cc882);
}

.montse {
  font-family: "Montserrat", sans-serif;
}
.open-sans {
  font-family: "Open Sans", sans-serif !important;
}

.poppins {
  font-family: "Poppins", sans-serif !important;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
}

.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

/* container fix */
.container {
  max-width: 100%;
}
.m-0 {
  margin: 0px;
}
/* end container fix */

.ml-0_5 {
  margin-left: 0.5em;
}
.ml-1 {
  margin-left: 1em;
}
.ml-2 {
  margin-left: 2em;
}
.m-0_5 {
  margin: 0.5em;
}
.mt-1 {
  margin-top: 1em;
}
.mt-2 {
  margin-top: 2em;
}
.mt-3 {
  margin-top: 3em;
}
.mt-0_5 {
  margin-top: 0.5em;
}
.mt-1_3 {
  margin-top: 1.3em;
}

.mr-0_5 {
  margin-right: 0.5em;
}
.mr-1 {
  margin-right: 1em;
}
.mr-2 {
  margin-right: 2em;
}
.mb-0_5 {
  margin-bottom: 0.5em;
}
.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}
.mb-3 {
  margin-bottom: 3em;
}

.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}

.t-l {
  text-align: left;
}
.t-c {
  text-align: center;
}
.t-r {
  text-align: right;
}
.t-j {
  text-align: justify;
}

.block {
  display: block;
}
.none {
  display: none;
}
.inline-block {
  display: inline-block;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.table-cell {
  display: table-cell;
}
.vertical-align-bottom {
  vertical-align: bottom;
}

.hidden {
  overflow: hidden;
}

.fill {
  object-fit: fill;
}
.contain {
  object-fit: contain;
}
.cover {
  object-fit: cover;
}

.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row !important;
}
.align-item-center {
  align-items: center !important;
}
.align-item-end {
  align-items: flex-end !important;
}
.flex-space-between {
  justify-content: space-between !important;
}
.flex-space-around {
  justify-content: space-around !important;
}
.flex-start {
  justify-content: start !important;
}
.flex-end {
  justify-content: flex-end !important;
}
.flex-center {
  justify-content: center !important;
}
.align-items-start {
  align-items: start;
}
.align-items-stretch {
  align-items: stretch;
}
.align-content-start {
  align-content: start;
}
.align-content-stretch {
  align-content: stretch;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.align-self-end {
  align-self: flex-end;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2;
}
.flex-grow-3 {
  flex-grow: 3;
}

/* particulares */
.gradient-home {
  background-image: url("/svg/home.svg"),
    linear-gradient(to right, #68ba81 30%, #bbdcbf);
  background-repeat: no-repeat, no-repeat;
  background-position: right bottom, left top;
  background-size: 65%, contain;

  border-bottom: solid 12px rgba(97, 202, 136, 0.5);
}
.w-100 {
  width: 100%;
}
.h-100 {
  min-height: 100% !important;
}
.clear-both {
  clear: both;
}

.pointer {
  cursor: pointer;
  transform: scale3d(0.98, 0.98, 0.98);
  -webkit-transform: scale3d(0.98, 0.98, 0.98);
  transition: all 0.3s;
}
.pointer:hover {
  transform: scale3d(1, 1, 1);
  -webkit-transform: scale3d(1, 1, 1);
}
.cursor-pointer {
  cursor: pointer;
}
.rotate-down {
  transform: rotate(90deg);
}

@media only screen and (max-width: 500px) {
  .t-m-l {
    text-align: left;
  }
  .t-m-c {
    text-align: center;
  }
  .t-m-r {
    text-align: right;
  }
  .t-m-j {
    text-align: justify;
  }
  .display-none-m {
    display: none;
  }

  .flex-m {
    display: flex;
  }
  .flex-wrap-m {
    flex-wrap: wrap;
  }
  .flex-column-m {
    flex-direction: column !important;
  }
  .flex-row-m {
    flex-direction: row !important;
  }
  .align-item-center-m {
    align-items: center !important;
  }
  .align-item-end-m {
    align-items: flex-end !important;
  }
  .flex-space-between-m {
    justify-content: space-between !important;
  }
  .flex-space-around-m {
    justify-content: space-around !important;
  }
  .flex-start-m {
    justify-content: start !important;
  }
  .flex-end-m {
    justify-content: flex-end !important;
  }
  .flex-center-m {
    justify-content: center !important;
  }
  .align-items-start-m {
    align-items: start;
  }
  .align-items-stretch-m {
    align-items: stretch;
  }
  .align-content-start-m {
    align-content: start;
  }
  .align-content-stretch-m {
    align-content: stretch;
  }
  .flex-shrink-0-m {
    flex-shrink: 0;
  }
  .align-self-end-m {
    align-self: flex-end;
  }
  .flex-grow-1-m {
    flex-grow: 1;
  }
  .flex-grow-2-m {
    flex-grow: 2;
  }
  .flex-grow-3-m {
    flex-grow: 3;
  }
}
