$position:(
    "t":top,
    "r":right,
    "b":bottom,
    "l":left,
    "tl": top-left,
    "tr": top-right,
    "br": bottom-right,
    "bl": bottom-left
);
$border:(
    "t":top,
    "r":right,
    "b":bottom,
    "l":left,
);
$radius:(
    "3": 3px,
    "6": 6px,
    "12": 12px,
    "24": 24px,
    "1em": 1em,
    "2em": 2em,
    "3em": 3em,
    "50p": 50%,
);

$colores:(
    "c1": #0D6E57,
    "c2": #020C72,
    "c3": #ffc500,
    "c4": #059b90,
    "c5": #dbdbdb,
    "c6": #00669d,
    "c7": #efefef,
    "c8": #fcfcfc,
    "c9": #69E1A0,
    "cw": #ffffff,
    "ci": #b1b1b1,
    "cb": #3F3D56,
    "ct": transparent,
    "xx": #ff00ff,
    "yy": #00ffff,
);
$bordersize:(
    "1": 1px,
    "2": 2px,
    "3": 3px,
    "4": 4px,
);
$fonsize:(
    "0_4em":0.4em,
    "0_6em":0.6em,
    "0_8em":0.8em,
    "1em":1em,
    "1_2em":1.2em,
    "1_4em":1.4em,
    "1_6em":1.6em,
    "1_8em":1.8em,
    "2em":2em,
    "2_2em":2.2em,
    "2_4em":2.4em,
    "2_6em":2.6em,
    "2_8em":2.8em,
);
$heights:(
    "6": 6em,
    "12": 12em,
    "16": 16em,
    "18": 18em,
    "24": 24em,
    "30": 30em,
    "100vh": 100vh, 
    "75vh": 75vh, 
    "50vh": 50vh, 
    "25vh": 25vh, 
);
$transparency:(
    "10": .1,
    "20": .2,
    "30": .3,
    "40": .4,
    "50": .5,
    "60": .6,
    "70": .7,
    "80": .8,
    "90": .9,
    "100":  1,
);

@each $color, $col in $colores{
    .bg{
        &-#{$color}{
            background-color: $col !important;
            &.inactive{
                background-color: #b1b1b1 !important;
            }
            &.unsel{
                // background-color: lighten( $col, 50% )
                background-color: transparentize( $col, .25 ) !important;
            }
        }
    }
    .bg{
        &-#{$color}{
            @each $trans, $tr in $transparency{
                &-#{$trans}{
                    background-color: rgba($col, $tr) !important;
                }
            }
        }
    }
    .tc{
        &-#{$color}{
            color: $col !important;
            &.inactive{
                color: #dbdbdb !important;
            }
            &.unsel{
                // background-color: lighten( $col, 50% )
                color: transparentize( $col, .35 ) !important;
            }
        }
    }
    .tc{
        &-#{$color}{
            @each $trans, $tr in $transparency{
                &-#{$trans}{
                    color: rgba($col, $tr) !important;
                }
            }
        }
    }
    .fc{
        &-#{$color}{
            fill: $col !important;
            &.inactive{
                fill: #dbdbdb !important;
            }
            &.unsel{
                // background-color: lighten( $col, 50% )
                fill: transparentize( $col, .35 ) !important;
            }
        }
    }
    .bc{
        &-#{$color}{
            @each $val, $num in $bordersize{
                &-#{$val}{
                    border: $num solid $col !important;
                }
                &.inactive{
                    border: $num solid #dbdbdb !important;
                }
                &.unsel{
                    // background-color: lighten( $col, 50% )
                    border: $num solid transparentize( $col, .35 ) !important;
                }
            }
        }
    }
    .bc{
        &-#{$color}{
            @each $val, $num in $bordersize{
                &-#{$val}{
                    @each $trans, $tr in $transparency{
                        &-#{$trans}{
                            border: $num solid rgba($col, $tr) !important;
                        }
                    }
                }
            }
        }
    }
            
    .loader.ball-scale-ripple-multiple>div.bc{
        &-#{$color}{
            @each $val, $num in $bordersize{
                &-#{$val}{
                    border: $num solid $col !important;
                }
            }
        }
    }
}
@each $fs, $em in $fonsize{
    .fs-{
        &#{$fs}{
            font-size: $em;
        }
    }
    .lh-{
        &#{$fs}{
            line-height: $em !important;
        }
    }
}
@each $fs, $em in $fonsize{
    .box-{
        &#{$fs}{
            width: $em !important;
            height: $em !important;
        }
    }
}
@each $he, $hei in $heights{
    .mh-{
        &#{$he}{
            max-height: $hei !important;
        }
    }
    .mih-{
        &#{$he}{
            min-height: $hei !important;
        }
    }
};

.mhw{
    min-height: 100vh;
}
.transition{
    transition: all .3s !important;
}
.vscroll{
    overflow-y: auto;
}

@each $prefix, $rad in $radius {
    .border-radius{
        &-#{$prefix}{
            border-radius: $rad !important;
        }
        @each $name, $pos in $position {
            &-#{$name}-#{$prefix}{
                border-#{$pos}-radius: $rad !important;
            }
        }
    }
};
@each $a, $b in $colores {
    .bb{
        @each $c, $d in $bordersize {
            &-#{$a}-#{$c}{
                border-bottom: $d solid $b;
            }
        }
    }
};

.m-0{
    margin: 0em !important;
}
.mb-0{
    margin-bottom: 0em !important;
}
.pt-0{
    padding-top: 0px !important;
}
@media only screen and (max-width: 500px) {
    .m-m-0{
        margin: 0em !important;
    }
    .mb-m-0{
        margin-bottom: 0em !important;
    }
    @each $fs, $em in $fonsize{
        .fs-m-{
            &#{$fs}{
                font-size: $em;
            }
        }
        .lh-m-{
            &#{$fs}{
                line-height: $em !important;
            }
        }
    }
}


// ----------------------
.switch-button {
    display: block;
}
.switch-button .switch-button__checkbox {
    display: none;
}
.switch-button .switch-button__label {
    background-color: #8d8d8d;
    width: 3rem;
    height: 1.5rem;
    border-radius: 1.5rem;
    display: block;
    position: relative;
}
.switch-button .switch-button__label:before {
    transition: .2s;
    display: block;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #fdffff;
    content: '';
    border-radius: 50%;
    box-shadow: inset 0px 0px 0px 1px rgb(145, 145, 145);
}
.switch-button .switch-button__checkbox:checked + .switch-button__label {
    background-color: #69E1A0;
}
.switch-button .switch-button__checkbox:checked + .switch-button__label:before {
    transform: translateX(1.5rem);
}

.fit{
    width: fit-content !important;
}
// .apexcharts-theme-light{
//     font-size: 12px !important;
// }
@each $color, $col in $colores{
    .hover-remarked-#{$color}:hover{
        background-color: rgba($col, .75);
    }
    .bg-#{$color}{
        @each $trans, $tr in $transparency {
            &-#{$trans}{
                background-color: rgba($col, $tr);
            }
        }
    }
}
@each $color, $col in $colores{
    input[type="radio"]:checked+.checked-remarked-#{$color}{
        background-color: $col;
        transform: scale3d(1,1,1);
        -webkit-transform: scale3d(1,1,1);
    }
}
input[type="radio"].radio-button{
    display: none;
}

@each $color, $col in $colores{
    @each $color2, $col2 in $colores{
        @each $trans, $tr in $transparency{
            @keyframes bg-fade-#{$color}-#{$color2}-#{$trans}{
                0% {
                    background-color: $col2;
                }
                10% {
                    background-color: rgba($col, $tr);
                }
                100% {
                    background-color: $col2;
                }
            }
            @-webkit-keyframes bg-fade-#{$color}-#{$color2}-#{$trans} {
                // 0% {
                //     background-color: rgba($col, $tr);
                // }
                // 100% {
                //     background-color: $col2;
                // }
                0% {
                    background-color: $col2;
                }
                10% {
                    background-color: rgba($col, $tr);
                }
                100% {
                    background-color: $col2;
                }
            }
            .bg-fade-#{$color}-#{$color2}-#{$trans} {
                animation-name: bg-fade-#{$color}-#{$color2}-#{$trans};
                animation-duration: 1.2s;
                animation-iteration-count: 1;
                animation-timing-function: ease-in-out;
                -webkit-animation-name: bg-fade-#{$color}-#{$color2}-#{$trans};
                -webkit-animation-duration: 1.2s;
                -webkit-animation-iteration-count: 1;
                -webkit-animation-timing-function: ease-in-out;
                background-color: $col2;
            } 
            @keyframes bc-fade-#{$color}-#{$color2}-#{$trans}{
                0% {
                    border-color: rgba($col, $tr);
                }
                100% {
                    border-color: $col2;
                }
            }
            @-webkit-keyframes bc-fade-#{$color}-#{$color2}-#{$trans} {
                0% {
                    border-color: rgba($col, $tr);
                }
                100% {
                    border-color: $col2;
                }
            }
            .bc-fade-#{$color}-#{$color2}-#{$trans} {
                animation-name: bc-fade-#{$color}-#{$color2}-#{$trans};
                animation-duration: 1.2s;
                animation-iteration-count: 1;
                animation-timing-function: ease-in-out;
                -webkit-animation-name: bc-fade-#{$color}-#{$color2}-#{$trans};
                -webkit-animation-duration: 1.2s;
                -webkit-animation-iteration-count: 1;
                -webkit-animation-timing-function: ease-in-out;
                border-color: $col2;
                border-width: 1px;
            } 
        }
    }
}
@each $color, $col in $colores{
    @each $color2, $col2 in $colores{
        @each $trans, $tr in $transparency{
            @keyframes bg-pulp-#{$color}-#{$color2}-#{$trans}{
                0% {
                    background-color:  rgba($col, $tr);
                }
                50% {
                    background-color: $col2;
                }
                100% {
                    background-color:  rgba($col, $tr);
                }
            }
            @-webkit-keyframes bg-pulp-#{$color}-#{$color2}-#{$trans} {
                0% {
                    background-color:  rgba($col, $tr);
                }
                50% {
                    background-color: $col2;
                }
                100% {
                    background-color:  rgba($col, $tr);
                }
            }
            .bg-pulp-#{$color}-#{$color2}-#{$trans} {
                animation-name: bg-pulp-#{$color}-#{$color2}-#{$trans};
                animation-duration: 2.3s;
                animation-iteration-count: 1;
                animation-timing-function: ease-in-out;
                -webkit-animation-name: bg-pulp-#{$color}-#{$color2}-#{$trans};
                -webkit-animation-duration: 2.3s;
                -webkit-animation-iteration-count: 1;
                -webkit-animation-timing-function: ease-in-out;
                background-color: $col2;
            } 
        }
    }
}
.sticky-64{
    position: sticky;
    position: -webkit-sticky;
    top: 64px;
    z-index: 999999;
}
.infinity{
    animation-iteration-count: infinite !important;
    -webkit-animation-iteration-count: infinite !important;
}
.bc-none{
    border: none;
}
.border-radius-0{
    border-radius: 0px;
}
.table{display: table;}
.table-row{display: table-row;}
.table-cell{display: table-cell;}
.w-100{width: 100%;}

@each $a, $b in $border{
    .border{
        @each $c, $d in $bordersize {
            @each $e, $f in $colores {
                    &-#{$a}-#{$c}-#{$e}{
                        border-#{$b}: $d solid $f;
                    }
                
            }
        }
    }
}
@each $a, $b in $border{
    .border{
        @each $c, $d in $bordersize {
            @each $e, $f in $colores {
                @each $g, $h in $transparency {
                    &-#{$a}-#{$c}-#{$e}-#{$g}{
                        border-#{$b}: $d solid rgba($f, $h);
                    }
                }
            }
        }
    }
}