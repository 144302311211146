/* body{
    background-image: linear-gradient(to right, #A7F5FF, #EDF6F5);
} */
.payment_div{
    margin-bottom: 100px;
}

.general-container{
    display: block;
    overflow: hidden;
    width: 100vw;
    /* height: fit-content; */
    height: 100vh;
    min-height: 50em;
}
.general-container2{
    display: block;
    overflow: hidden;
    width: 100vw;
    /* height: fit-content; */
    /* height: 100vh; */
    min-height: 100vh;
}
.general-container3{
    display: block;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    min-height: 50em;
}
.general-container4{
    display: block;
    overflow: hidden;
    width: 100vw;
    /* height: 100vh; */
    min-height: 60em;
}
.offset4rewards{
    overflow: hidden;
    position: absolute;
    z-index: 6;
    width: 100%;
}
.offset4rewards2{
    /* overflow: hidden; */
    position: absolute;
    z-index: 6;
    /* width: 100%; */
}

.form-container-box{
    display: block;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}
.error-msg{
    color: tomato;
    background-color: rgba(255, 99, 71, .2);
    border: 1px solid tomato;
    padding: 6px;
    border-radius: 3px;
}
.tomato{color: tomato;}

.all-clouds{
    display: block;
    overflow: hidden;
    position: absolute;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    min-height: 50em;

}
.cloud1,
.cloud2,
.cloud3,
.cloud4
{
    display: block;
    overflow: hidden;
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.cloud1{
    width: 150vw;
    height: 100vh;
    background-image: url('/svg/cloud1.svg');
    left: -40vw;
    bottom: -25vh;
    z-index: 1;
}
.cloud2{
    width: 100vw;
    height: 75vh;
    background-image: url('/svg/cloud2.svg');
    left: -40vw;
    bottom: -20vh;
    z-index: 2;
}
.cloud3{
    width: 100vw;
    height: 75vh;
    background-image: url('/svg/cloud3.svg');
    right: -40vw;
    bottom: -20vh;
    z-index: 3;
}
.cloud4{
    width: 75vw;
    height: 50vh;
    background-image: url('/svg/cloud4.svg');
    left: 20vw;
    bottom: -15vh;
    z-index: 4;
}
.btn-link-offset4rewards{
    display: block;
    overflow: hidden;
    background-image: linear-gradient(to right, transparent, transparent);
    border-radius: 12px;
    border: 2px solid #5CC882;
    color:black !important;
    cursor: pointer;
}
.btn-link-offset4rewards.active{
    color: #ffffff !important;
    background-image: linear-gradient(to right, #0FA9C9, #5CC882);
    cursor: default;
}

.btn-disabled{
    color: #FFFFFF;
    background-image: linear-gradient(to right, #71bece, #9bcaac);
    cursor: not-allowed;
}

.input-disabled{
    color: #ffffff;
    border: 1px #0C6D56;
    background-image: linear-gradient(to right, #71bece, #9bcaac);
    cursor: not-allowed;
}
.form-box{
    display: block;
    overflow: hidden;
    width: 100%;
    background-color: rgba(255,255,255,1);
    border-radius: 6px;
    box-shadow: 0px 2px 3px rgba(128,128,128,0.2);
}
.input-text{
    display: block;
    overflow: hidden;
    width: 100%;
    height: 2em;
    line-height: 2em;
    padding: 0em 0.5em;
    border-radius: 12px;
    border: 2px solid #5CC882;
    letter-spacing: 1px;
}
.input-text.color4{
    border: 2px solid #DFFED3;
    color: #DFFED3 !important;
    letter-spacing: auto;
    background-color: transparent;
}
.input-text.color4::-webkit-input-placeholder, .input-text.color4::placeholder{
    color: #DFFED3 !important;
}

.btn-calculate{display: block;}
.ant-form-item::not(.btn-calculate){
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.ant-col{
    flex-grow: 2;
}
.ant-col:first-of-type{
    flex-grow: 1;
}
.fua{ display: flex;}
.fua .ant-form-item-label{
    width: 4em;
    min-width: 100px;
}
.ant-row.ant-form-item{
    margin-bottom: 1em;
}
.ant-radio-group{
    display: flex;
    justify-content: space-around;
}
.ant-radio{
    padding-right: 0.5em;
}
.ant-btn{
    border: none;
    width: 70%;
    margin: 0px auto;
}
.ant-btn span{
    color: white;
    font-weight: 500;
}
.ant-form-explain{
    color: #0D6E57;
    font-style: italic;
    text-align: right;
    font-size: 0.8em;
    padding-top: 0.3em;
    padding-right: 0.6em;
    transition: all .3s;
}

.green-project{
    opacity: .98;
    transform: scale3d(0.98,0.98,0.98);
    -webkit-transform: scale3d(0.98,0.98,0.98);
    transition: all .3s;
}
.green-project:hover{
    opacity: 1;
    transform: scale3d(1,1,1);
    -webkit-transform: scale3d(1,1,1);
}
.max-height-12{ height: 12em !important; }


.bg-project{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 20px solid rgba(255,255,255,0.3);
}
.br{
    display: block;
    margin: 1.5em 0;
}

.mountain,
.sun,
.projectclouds
.mountain2,
.sun2,
.projectclouds2,
.monodepie1,
.monodepie2{
    display: block;
    overflow: hidden;
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.all-project{
    display: block;
    overflow: hidden;
    position: absolute;
    z-index: 0;
    width: 100vw;
    height: 100vh;

}
.mountain{
    width: 100vw;
    height: 100%;
    background-image: url('/svg/project.svg');
    /* left: 0;
    bottom: 0; */
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 50%;
    z-index: 2;
}
.sun{
    width: 200px;
    height: 200px;
    background-image: url('/svg/sol1.svg');
    left: 15em;
    top: 1em;
    z-index: 1;
}
.projectclouds{
    width: 450px;
    height: 450px;
    background-image: url('/svg/nube1.svg');
    left: 2em;
    top: -5em;
    z-index: 1;
}

.all-account{
    display: block;
    overflow: hidden;
    position: absolute;
    z-index: 0;
    width: 100vw;
    height: 100vh;
}
.mountain2{
    width: 100vw;
    height: 100%;
    background-image: url('/svg/project.svg');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 50%;
    z-index: 2;
}
.sun2{
    width: 200px;
    height: 200px;
    background-image: url('/svg/sol1.svg');
    /* margin-top: 4em;
    margin-right: -15em; */
    top: 40px;
    right: 80px;
    z-index: 1;
}
.projectclouds2{
    width: 350px;
    height: 350px;
    background-image: url('/svg/nube1.svg');
    /* margin-top: 15em;
    margin-right: 2em; */
    top: 200px;
    right: 10px;
    z-index: 1;
}
.monodepie1{
    width: 20%;
    height: 50%;
    background-image: url('/svg/p1.svg');
    left: 0px;
    bottom: 0px;
    background-position: center bottom;
    overflow: visible;
}
.monodepie2{
    width: 22%;
    height: 70%;
    background-image: url('/svg/p2.svg');
    right: 0px;
    bottom: 0px;
    background-position: center bottom;
    overflow: visible;
}
.caja-avion{
    display: flex;
    align-items: center;
    justify-items: center;
}
.avion{
    width: 30%;
    -webkit-animation: volando 4s infinite alternate linear;
    animation: volando 4s infinite alternate linear;
}

/* loader */
.loader {
    display: block;
    width: 50px;
    height: 50px;
    margin: 0px auto;
    padding-left: 25px;
}


.ball-scale-ripple-multiple>div {
    border-color: white;
}

/* estilos para tooltip*/
.ant-tooltip {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1060;
  display: block;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: '';
  pointer-events: auto;
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px;
}
.ant-tooltip-placement-top .ant-tooltip-arrow::before,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow::before,
.ant-tooltip-placement-topRight .ant-tooltip-arrow::before {
  -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
          box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(-6.53553391px) rotate(45deg);
      -ms-transform: translateY(-6.53553391px) rotate(45deg);
          transform: translateY(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: -5.07106781px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow::before,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow::before,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow::before {
  -webkit-box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
          box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(6.53553391px) rotate(45deg);
      -ms-transform: translateX(6.53553391px) rotate(45deg);
          transform: translateX(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: -5.07106781px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow::before,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow::before,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow::before {
  -webkit-box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
          box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(-6.53553391px) rotate(45deg);
      -ms-transform: translateX(-6.53553391px) rotate(45deg);
          transform: translateX(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow::before {
  -webkit-box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
          box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(6.53553391px) rotate(45deg);
      -ms-transform: translateY(6.53553391px) rotate(45deg);
          transform: translateY(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}

.go-invest{
        min-height: 18em !important;
        max-height: 20em !important;
    }


@-webkit-keyframes volando {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes volando {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@media only screen and (max-width: 500px) {
    .general-container3{
        min-height: 75em !important;
    }
}

@media only screen and (max-width: 728px) {
    .cloud1,
    .cloud2,
    .cloud3,
    .cloud4,

    .mountain,
    .sun,
    .projectclouds,

    .mountain2,
    .sun2,
    .projectclouds2,
    .monodepie1,
    .monodepie2
    {
        display: none;
    }
    .ant-col.ant-form-item-control-wrapper{
        width: 100%;
    }
    .ant-form-item{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .ant-col{
        flex-grow: 1;
    }
    .fua .ant-form-item-label:first-of-type{
        flex-grow: 1;
        padding-left: 0.5em;
    }
    .ant-btn{
        border: none;
        width: 100%;
    }
    .offset4rewards.no-w-100{
        width: auto !important;
    }
}
