.cookiesbox{
  position: fixed;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.9), rgba(167, 245, 255, 0.9));
  // background-color: rgba(255, 255, 255, 0.9);
  left: 0px;
  bottom: 0px;
  z-index: 999999;
  & a{
      color: #0C6D56;
  }
}
.btn-accept-cookie{
  cursor: pointer;
  transition: all .3s;
}
.btn-accept-cookie:hover{
  transform: scale3d(1.03,1.03,1.03);
}

.vw-100{
  width: calc(100vw - 4em) !important;
}
@media only screen and (max-width: 500px) {
  .vw-100{
      width: calc(100vw - 2em);
  }
}