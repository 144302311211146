/* Posicionamiento
Box Model
Tipografía
Visuales
Otros */
:root {
    --lightGrey: #f2f2f2;
    --darkGrey: #dbdbdb;
    --veryDarkGrey: #b1b1b1;
    --borderGray: #acacac;
    --darkBlue: #000071;
    --lightBlue: #7088ab;
    --black: #25233d;
    --buttonYellow: #ffc500;
    --transferGreen: #69e1a0;
}
main {
    width: 100%;
}
.main-orderMaker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 16px;
    margin: 0 auto;
    border: 1px solid var(--darkGrey);
    border-radius: 8px;
}
.container-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 870px;
    margin-bottom: 16px;
    padding: 4px;
    border: 1px solid var(--darkGrey);
    border-radius: 8px;
}
.container-title p {
    display: inline-block;
    align-self: center;
    margin: 0 auto;
    font-size: 1.3rem;
    color: var(--darkBlue);
}
.container-title p:nth-child(1) {
    font-size: 0.8rem;
}
.container-title span {
    font-size: 2rem;
    font-weight: bolder;
}
.container-title p:nth-child(2) {
    display: inline-block;
    width: 13.75rem;
    margin: 0 auto;
    align-self: center;
    font-size: 1.3rem;
}
.container-currency_options {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: auto;
    padding: 3px;
    margin-bottom: 12px;
}
.container-currency_options div:nth-child(1) {
    margin-left: 0;
}
.container-currency_options div:nth-child(3) {
    margin-right: 0;
}
.container-number_selector {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 33.3%;
    height: 130px;
    max-width: 200px;
    min-width: 150px;
    padding: 8px;
    margin: 4px;
    border: 1px solid var(--borderGray);
    border-radius: 8px;
    background-color: var(--darkGrey);
}

.container-number_selector p {
    font-size: 12px;
    text-align: center;
}
.container-number_selector p:nth-child(2) {
    font-size: 0.55rem;
    margin-bottom: 0;
}

.container-number_selector input  {
    text-align: center;
    width: 100%;
    height: 50px;
    margin-bottom: 12px;
    font-size: 2rem;
    font-weight: bold;
    border-radius: 4px;
    border: none;
}
.number_selector-input3 input {
    margin-bottom: 0;
}

.container-button_putOrder {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.button-putOrder {
    width: 80%;
    margin: 12px;
    padding: 4px;
    font-size: 1.25rem;
    background-color: var(--borderGray);
    border-radius: 25px;
    cursor: pointer;
}
.button-putOrder:hover {
    transform: scale3d(1.02, 1.02, 1.02);
    -webkit-transform: scale3d(1.02, 1.02, 1.02);
    transition: all 0.3s;
}
.button-putOrder:enabled {
    background-color: var(--buttonYellow);
}

.button-transferNeeded{
    width: 80%;
    margin: 12px;
    padding: 4px;
    font-size: 1.25rem;
    background-color: var(--borderGray);
    border-radius: 25px;
}
.button-transferNeeded:enabled {
    background-color: var(--transferGreen);
}
.container-successfully_order {
    background-color: var(--darkBlue);
}
.button-back_exchange {
    background-color: var(--buttonYellow);
}