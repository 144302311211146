/* .sticky{
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
}
.top-header{
  padding: 1em;
}
.header{
  display: flex;
  justify-content: space-between;
  padding-top: .5em;
}
.logo1{
    width: 114px;
    height: 40px;
    text-indent: -99999px;
    background-image: url('/svg/logo1.svg');
    background-repeat: no-repeat;
    background-position: center right;
  }
.header-link{
    padding-bottom: 2px;
    border-bottom: 3px solid rgba(255,255,255,0);
    transition: all .3s;
  }
.header-link.active{
    border-bottom: 3px solid #3F3D56;
  } */


/* header */
.header {
  display: block;
  background-color: #ffffff;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .1);
  width: 100%;
  height: 64px;
  z-index: 900;
}

.header div {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #fff;
}

.header a {
  display: block;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s;


  padding: 20px 20px;
  text-decoration: none;
}

.header a.active {
  /* border-bottom: 3px solid #3F3D56; */
  background-color: #BBDCBF;
}

/* .header a:hover,
  .header .menu-btn:hover {
    background-color: #f4f4f4;
  }*/

.header .logo1 {
  display: block;
  float: left;
  width: 114px;
  height: 40px;
  margin-top: 0.5em;
  margin-left: 1em;
  text-indent: -99999px;
  background-image: url('/svg/logobeta.svg');
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer;
}

.header .status {
  display: block;
  float: left;
  width: 114px;
  height: 40px;
  padding-top: 1em;
  margin-left: 1em;
  text-indent: -99999px;
  /*   background-image: url('/svg/logo1.svg'); */
  background-repeat: no-repeat;
  background-position: center right;

  /* display: block;
    overflow: hidden;
    float: left;
    background-color: silver;
    width: 1000px;
    height: 10px;
    border-radius: 10px;
    margin: 0.3em 0.3em 0px 0px; */
}


/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked~.menu {
  max-height: 340px;
}

.header .menu-btn:checked~.menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked~.menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked~.menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
  top: 0;
}

.point {
  display: block;
  overflow: hidden;
  float: left;
  background-color: silver;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin: 0.3em 0.3em 0px 0px;
}

.point.active {
  background-color: #5CC882;
}

.menu.montse a {
  color: #3F3D56 !important;
}

.btn-link-language {
  display: block;
  overflow: hidden;
  background-image: linear-gradient(to right, transparent, transparent);
  border-radius: 6px;
  border: 2px solid #5CC882;
  cursor: pointer;
}

/* 48em = 768px */

@media (min-width: 1024px) {
  .header a {
    float: left;
  }

  .header a {
    /* padding: 20px 30px; */
    padding: 20px 10px 3px 10px;
    margin-right: 5px;
    border-bottom: 3px solid rgba(255, 255, 255, 0);
  }

  .header a.active {
    border-bottom: 3px solid #3F3D56;
    background-color: transparent;
  }

  .header .menu {
    display: inline-block;
    clear: none;
    float: right;
    max-height: none;
  }

  .header .menu-icon {
    display: none;
  }
}