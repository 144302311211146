button {
    margin-top: 24px;
}
.data-span {
    color: white;
}
.data-span b {
    color: white;
}
span > b {
    color: white;
}
